const firebaseConfig = {
  apiKey: "AIzaSyBO-97crBWy9w_olF5qKIy2GmntKm8jkl8",
  authDomain: "namsoridentities.firebaseapp.com",
  databaseURL: "https://namsoridentities.firebaseio.com",
  projectId: "namsoridentities",
  storageBucket: "namsoridentities.appspot.com",
  messagingSenderId: "578244426610",
  appId: "1:578244426610:android:07982d13297dbfe8"
}

export default firebaseConfig

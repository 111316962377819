/* ================================================================================================
                                        GESTION DES OBJETS
==================================================================================================*/



import {// ./number
    isNumeric
} from "./number.js";



//------------------------- Convertir un array en objet -------------------------//

    export const convertArrayInObject = (array, newObject = {}) => {
        array.forEach(value => {
            if(isObject(value)){
                Object.entries(value).forEach(([key, value]) => newObject[key] = value)
            };
            if(Array.isArray(value)){
                convertArrayInObject(value, newObject)
            }
        });
        return newObject;
    };



//------------------------- Cloner un objet -------------------------//

    export const cloneObj = obj => {
        
        let copy;

        if (null == obj || "object" != typeof obj) return obj;

        if (obj instanceof Date) {
            copy = new Date();
            copy.setTime(obj.getTime());
            return copy;
        }

        if (obj instanceof Array) {
            copy = [];
            for (let i = 0, len = obj.length; i < len; i++) {
                copy[i] = cloneObj(obj[i]);
            }
            return copy;
        }

        if (obj instanceof Object) {
            copy = {};
            for (let attr in obj) {
                if (obj.hasOwnProperty(attr)) copy[attr] = cloneObj(obj[attr]);
            }
            return copy;
        }

        throw new Error("Impossible de copier l'objet.");
    };


//------------------------- Tester si object -------------------------//

    export const isObject = target => {
        return(
          target !== null && 
          typeof target === 'object' && 
          Object.prototype.toString.call(target) === '[object Object]'
        )
      };


//------------------------- Fonction pour merger profondément deux objets -------------------------//

    export const mergeDeep = (target, source, forbidUndefined) => {
        
        if (!isObject(target) || !isObject(source)) {
            return source;
        }
        
        Object.keys(source).forEach(key => {
            const targetValue = target[key];
            const sourceValue = source[key];
        
            if (isObject(targetValue) && isObject(sourceValue)) {
                target[key] = mergeDeep(Object.assign({}, targetValue), sourceValue);
            } else {
                const value = forbidUndefined ? sourceValue || target[key] || '' : sourceValue || target[key];
                target[key] = value;
            };
        });
        
        return target;
    };




//------------------------- variante de la fonction pour merger profondément deux objets -------------------------//   
 
    export const mergeDeepComplex = (target, source, oldTarget) => {

        const regex = /--(\w+)--/;

        if (!isObject(target) || !isObject(source)) {
            return source;
        };
        
        if(!oldTarget){
            oldTarget = {...target};
            Object.entries(target).forEach(([key, value]) => {
                if(Array.isArray(value)) target[key] = value.filter(value => !regex.exec(value))
            })
        };

        Object.keys(source).forEach(key => {
            let targetValue = target[key];
            let sourceValue = source[key];

            if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
                const arrayToConcat = [];
                const valueToParse = oldTarget[key] || targetValue;

                valueToParse.forEach(value => {
                    const m = regex.exec(value);

                    if(m?.[1]){
                        const valueToDelete = isNumeric(m?.[1]) ? Number(m?.[1]) : m?.[1];
                        sourceValue = sourceValue.filter(value => value !== valueToDelete)
                        targetValue = targetValue.filter(value => value !== m[0])
                    } else if(!sourceValue.includes(value)) arrayToConcat.push(value)
                })

                target[key] = arrayToConcat.concat(sourceValue);

            } else if (isObject(targetValue) && isObject(sourceValue)) {
                const newTargetValue = {...targetValue};

                Object.entries(newTargetValue).forEach(([key, value]) => {
                    if(Array.isArray(value)) newTargetValue[key] = value.filter(value => !regex.exec(value))
                })

                target[key] = mergeDeepComplex(Object.assign({}, newTargetValue), sourceValue, targetValue);

            } else {
                target[key] = targetValue === undefined || targetValue === "" ? sourceValue : targetValue;
            }
        });
                        
        return target;
    };





//------------------------- Fonction pour trouver les clés profondément -------------------------//

    export const findAllByKey = (obj, keyToFind, caseInsensitive) => {

        return Object.entries(obj)
          .reduce((acc, [key, value]) => ((caseInsensitive ? key.toLowerCase() : key) === (caseInsensitive ? keyToFind.toLowerCase() : keyToFind))
            ? acc.concat(value)
            : (typeof value === 'object')
            ? acc.concat(findAllByKey(value, keyToFind, caseInsensitive))
            : acc
          , [])
      };


//------------------------- Fonction pour trouver les clés profondément avec filtre sur object -------------------------//

    export const findAllByKeyAlt = (obj, keyToFind, caseInsensitive) => {

        return Object.entries(obj)
          .reduce((acc, [key, value]) => ((caseInsensitive ? key.toLowerCase() : key) === (caseInsensitive ? keyToFind.toLowerCase() : keyToFind))
            ? acc.concat(value)
            : (isObject(value))
            ? acc.concat(findAllByKeyAlt(value, keyToFind, caseInsensitive))
            : acc
          , [])
      };


//------------------------- Fonction pour trouver toutes les clés d'un objet -------------------------//

    export const getDeepKeys = (obj, strictKey, findObject) => {
        let keys = [];
        for(let key in obj) {
            if((typeof obj[key] !== "object" || Array.isArray(obj[key]) || !strictKey) && !findObject) keys.push(key);
            if(typeof obj[key] === "object" && !Array.isArray(obj[key])) {
                if(findObject) keys.push(key);
                const subkeys = getDeepKeys(obj[key], strictKey, findObject);
                if(!strictKey){
                    keys = keys.concat(subkeys.map((subkey) => {
                        return key + "." + subkey;
                    }));
                }else{
                    keys = keys.concat(subkeys)
                }
            }
        }
        return keys;
    };






//------------------------- Extraire les clés et valeurs d'un objet avec callback en options -------------------------//

    export const geetDeepKeysAndValues = (obj, params, formatingCallback) => {

        params = params || {};

        const {
            valueFilter, //Prends "findObject" || "filterObject" || undefined = (findAll) comme valeurs;
            valueToReturn, //Prends "keyAndValue" || undefined = (key) comme valeurs;
            keyFilter //Prends "keyWithoutPath" || undefined comme valeurs;
        } = params;

        const findOnlyObject = valueFilter === "findObject"; //retourne uniquement les clés avec un objet comme valeur
        const filterObject = valueFilter === "filterObject"; //retourne uniquement les clés qui n'ont pas d'objet comme valeur
        const keyWithoutPath = keyFilter === "keyWithoutPath"; //les clés seront remontés sans leurs paths (ex: "subKey.key" donnera "key")

        const formatValueToReturn = data => valueToReturn === "keyAndValue" ? {keys: data.keys, value: data.value} : {keys: data.keys};
        const formatData = data => formatingCallback ? formatingCallback(formatValueToReturn(data)) : formatValueToReturn(data);

        let keysList = [];

        for(let key in obj) {

            if((!isObject(obj[key]) || !filterObject) && !findOnlyObject) {
                keysList.push(formatData({keys: [key], value: obj[key]}));
            };

            if(isObject(obj[key])) {

                if(findOnlyObject) keysList.push(formatData({keys: [key], value: obj[key]}));

                let subkeys = geetDeepKeysAndValues(obj[key], params);

                if(filterObject) subkeys = subkeys.filter(({keys, value}) => !isObject(value));

                if(!keyWithoutPath){
                    keysList = keysList.concat(subkeys.map(subkey => {
                        return formatData({keys: [key, ...subkey.keys], value: subkey.value});
                    }));
                }else{
                    keysList = keysList.concat(subkeys.map(subkey => {
                        return formatData({keys: [key, ...subkey.keys], value: subkey.value});
                    }));
                }
            }
        }

        return keysList;
    };





//------------------------- Trouver le path d'une clé -------------------------//

    export const findKeyPath = (obj, key, caseInsensitive) => {
        const isEquiv = (prop, key) => caseInsensitive ? prop.toLowerCase() === key.toLowerCase() : prop === key;
        for (var prop in obj) {
            if (isEquiv(prop, key)) {
                return prop;
            } else if (typeof obj[prop] == "object") {
                var result = findKeyPath(obj[prop], key, caseInsensitive);
                if (result) {
                    return prop + '.' + result;
                }
            }
        }
        return null;
    };




//------------------------- Trouver les paths d'une clé -------------------------//

    export const findAllKeyPaths = (obj, key, prev = '') => {
        const result = []
    
        for (let k in obj) {
            let path = prev + (prev ? '.' : '') + k;
        
            if (k === key) {
                result.push(path)
            }
            if (typeof obj[k] == 'object') {
                result.push(...findAllKeyPaths(obj[k], key, path))
            }
        }
    
        return result
    };
  


//------------------------- Trouver les paths d'une valeur -------------------------//

    export const findAllValuePaths = (obj, key, value, prev = []) => {
        const result = []

        for (let k in obj) {
            const path = [...prev, k];
        
            if (k === key && obj[k] === value) {
                result.push(path)
            }
            if (typeof obj[k] == 'object') {
                result.push(...findAllValuePaths(obj[k], key, value, path))
            }
        }

        return result
    };



//------------------------- Fonction pour récupérer une propriété profonde avec un path -------------------------//

    export const findDeepValue = (obj, path, popNumber) => {

        const newPath = [...path];
        let newObj = {...obj};

        if(popNumber)newPath.splice(newPath.length - popNumber);

        for (var i=0, len=newPath.length; i<len; i++){
            newObj = newObj?.[newPath[i]];
        };

        return newObj;
    };





//------------------------- Mettre à jour une proprieté profonde avec un path -------------------------//

    export const updateDeepValue = (obj, path, value) => {

        var schema = obj;
        var len = path.length;

        for(var i = 0; i < len-1; i++) {
            var elem = path[i];
            if( !schema[elem] ) schema[elem] = {}
            schema = schema[elem];
        };
        schema[path[len-1]] = value;

        return obj;
    };





//------------------------- Supprimer une proprieté profonde avec un path -------------------------//

    export const deleteDeepValue = (obj, path) => {

        let stringifyPath = path.join(".");

        if (!obj || !stringifyPath) {
          return;
        }
      
        if (typeof stringifyPath === 'string') {
          stringifyPath = stringifyPath.split('.');
        }
      
        for (var i = 0; i < stringifyPath.length - 1; i++) {
      
          obj = obj[stringifyPath[i]];
      
          if (typeof obj === 'undefined') {
            return;
          }
        }
      
        delete obj[stringifyPath.pop()];
      };
      


//------------------------- Trouver le dernier index répondant à une condition dans un tableau -------------------------//

      export const findLast = (array, condition) => {
        const reverseArray = array.slice().reverse();
        const index = reverseArray.findIndex(condition);
        const value = reverseArray.find(condition)
        const count = array.length - 1
        const finalIndex = index >= 0 ? count - index : index;
        return {index: finalIndex, value};
      };





//------------------------- Filter object -------------------------//

    export const filterObject = (obj, predicate) => {
        let result = {}, key;

        for (key in obj) {
            if (obj.hasOwnProperty(key) && !predicate(obj[key])) {
                result[key] = obj[key];
            }
        }

        return result;
    };





//------------------------- Fonction pour comparer deux objets -------------------------//

    export const compareObj = (obj1, obj2, keyPath) => {

        keyPath = !keyPath ? ["Obj"] : keyPath;

        for (let p in obj1) {

            if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) return {code: 400, msg: `The key ${keyPath.join(".")}.${p} does not exist`};
    
            switch (typeof (obj1[p])) {
    
                case 'object':
                    const objectValue = compareObj(obj1[p], obj2[p], [...keyPath, p]);
                    if (objectValue) return objectValue;
                    break;

                case 'function':
                    if (obj1[p](obj2[p])) return {code: 400, msg: `Does not meet the conditions: ${keyPath.join(".")}.${p} for ${obj1[p]}`};
                    break;

                default:
                    const typeof1 = typeof obj1[p];
                    const typeof2 = typeof obj2[p];
                    if ( typeof1 !== typeof2) return {code: 400, msg: `Wrong format: ${keyPath.join(".")}.${p} is is of type ${typeof2} rather than ${typeof1}`};
            }
        }
    
    return false;
};




//------------------------- Fonction pour comparer deux objets simplement -------------------------//

    export const isObjectsAreDifferent = (obj1, obj2) => {
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        if(keys1.length !== keys2.length){
            return true;
        };

        for (let key of keys1) {
        if(isObject(obj1[key]) && isObject(obj2[key])){
            return isObjectsAreDifferent(obj1[key], obj2[key])

        }else if (obj1[key] !== obj2[key]) {
            return true;
        };
        };
        return false;
    };
/* ================================================================================================
                                        MANIPULATION DU DOM
==================================================================================================*/


//--------------------- Manipulation du DOM ---------------------//

    export const byId = id => document.getElementById(id);

    export const byClass = className => document.getElementsByClassName(className);

    export const byName = name => document.getElementsByTagName(name);

    export const bySelector = (selector, all) => {
      if (all) return document.querySelectorAll(selector);
      else return document.querySelector(selector);
    };

    export const toggleClass = (el, className) => el?.classList?.toggle(className);

    export const addClass = (el, className) => el?.classList?.add(className);

    export const removeClass = (el, className) => el?.classList?.remove(className);

    export const addEvent = (el, action, callback) => el?.addEventListener(action, callback, false);

    export const removeEvent = (el, action, callback) => el?.removeEventListener(action, callback);

    export const findPosition = (obj) => {
      var curtop = 0;
      if (obj.offsetParent) {
          do {
              curtop += obj.offsetTop;
          } while (obj = obj.offsetParent);
      return [curtop];
      }
    };


//--------------------- Gestion des modal ---------------------//

export const hideModal = el => {
  const body = bySelector('body')
  removeClass(body, 'no-scroll');
  removeClass(el, 'showed');
  if(!el.classList.value.includes('hide')){
    addClass(el, 'out');
    addClass(el, 'hide');
  }
};

export const showModal = el => {
  const body = bySelector('body')
  addClass(body, 'no-scroll');
  addClass(el, 'showed');
  removeClass(el, 'out');
  removeClass(el, 'hide');
};
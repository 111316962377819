import React, {useReducer, useMemo, useEffect} from "react";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import { useInitUser } from "./useInitUser";
import { useUpdateUserData } from "./useUpdateUserData";
import { FirebaseContext, useAuth } from "../firebase";




export const UserData = React.createContext({});
export const UpdateUserData = React.createContext({});



function userReducer(state, action) {
    switch (action.type) {
        case 'init':
            return {...state, user: action.user, updateKey: Math.random()};
        case 'update':
            if(!action.isInit){
                window.localStorage.setItem("updateKey", state.updateKey);
            };
            return {...state, ...action.values};
        case 'reset':
            return {};
      default:
            throw new Error();
    };
};
  
const initialUserData = {
};



export const Provider = (props) => {

        const [userData, dispatchUserData] = useReducer(userReducer, initialUserData);
        const {user, firebase, loading} = useAuth();
        const initUser = useInitUser({userData, dispatchUserData});
        const updateUserData = useUpdateUserData({...initUser, userData, dispatchUserData, firebase});
    
        useMemo(() => {
            if(user && !loading){
                dispatchUserData({type: "init", user});
            };
            if(!user && !loading){
                dispatchUserData({type: "reset"});
                window.localStorage.clear();
            };
        }, [user, loading]);



        useEffect(() => {
            let active = true;

            const updateData = () => {
                if(user && !loading && active && userData.updateKey){
                    let needsUpdate = window.localStorage.getItem('updateKey');
                    if(needsUpdate){
                        needsUpdate = JSON.parse(needsUpdate).updateKey !== userData.updateKey;
                    }
                    if(needsUpdate){
                        dispatchUserData({type: "init", user});
                        window.localStorage.removeItem('updateKey');
                    };
                };
            };
            window.addEventListener('storage', updateData);

            return () => { 
                active = false;
                window.removeEventListener('storage', updateData);
            };

        }, [user, loading, userData.updateKey]);
    
        
        return(
            <UserData.Provider value={userData}>
            <UpdateUserData.Provider value={updateUserData}>
            <FirebaseContext.Provider value={{ user, firebase, loading }}>
                {props.children}
            </FirebaseContext.Provider>
            </UpdateUserData.Provider>
            </UserData.Provider>
        )
}
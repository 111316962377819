import { useMemo } from "react";
import { Flash } from "../functions/Flash";
import { validateVatId, analyzeBillingInfo } from "../functions/utils";
import { navigate } from "gatsby-link";
import axios from "axios";

import { useUpdateUserData as lang } from "../../lang/utils/user-hooks-and-provider/lang"


const requestAPIPath = "https://v2.namsor.com/NamSorAPIv2/api2/json/";
// const requestAPIPath = "https://v2uk.namsor.com/NamSorAPIv2/api2/json/";



export const useUpdateUserData = ({firebase, userTokenRequestGet, apiKeyRequestGet, userTokenRequestPost, userTokenAndApiKeyRequestGet, userData, dispatchUserData}) => {


    // ================================= UPDATE ================================= //

        //------------- Request for update name -------------//

            const updateName = useMemo(() => {
                return displayName => {

                    if(displayName === userData.user.displayName){
                        return ({ msg: lang.flash_update_empty, state: "classic" });
                    };
                    
                    return firebase.updateDisplayName(displayName)
                        .then(() => {
                            if(typeof window.gtag === "function"){
                                window.gtag('event', 'update', {
                                    'event_category' : 'Update name',
                                    'event_result' : 'Success',
                                });
                            };

                            userTokenRequestGet("userInfo");

                            return({ msg: lang.flash_update_name_success, state: "success" });
                        })
                        .catch(() => {
                            if(typeof window.gtag === "function"){
                                window.gtag('event', 'update', {
                                    'event_category' : 'Update name',
                                    'event_result' : 'Error',
                                });
                            };

                            return({ msg: lang.flash_update_name_error, state: "error" });
                        });
                };
            }, [userData.user, firebase?.updateDisplayName]);



        //------------- Request for update email -------------//

            const updateEmail = useMemo(() => {
                return email => {

                    if(email === userData.user.email){
                        return ({ msg: lang.flash_update_empty, state: "classic" });
                    };

                    return firebase.updateEmail(email)
                        .then(() => {
                            if(typeof window.gtag === "function"){
                                window.gtag('event', 'update', {
                                    'event_category' : 'Update email',
                                    'event_result' : 'Success',
                                });
                            };

                            userTokenRequestGet("userInfo");

                            return({ msg: lang.flash_update_email_success, state: "success" });
                        })
                        .catch(err => {

                            if(err.message === "Firebase: Error (auth/requires-recent-login)."){
                                return({ msg: lang.flash_connexion_old, state: "warning" });
                            };

                            if(typeof window.gtag === "function"){
                                window.gtag('event', 'update', {
                                    'event_category' : 'Update email',
                                    'event_result' : 'Error',
                                });
                            };

                            return({ msg: lang.flash_update_email_error, state: "error" });
                        });
                }
            }, [userData.user, firebase?.updateEmail]);



            //------------- Request for update biling info -------------//

                const updateBillingInfo = useMemo(() => {
                    return localBillingInfo => {
                        const {billingIsEmpty, objectsAreDifferent} = analyzeBillingInfo(localBillingInfo, userData.billingInfo);

                        if(billingIsEmpty || !objectsAreDifferent){
                            return ({ msg: lang.flash_update_empty, state: "classic" });
                        };

                        if(!validateVatId(localBillingInfo)){
                            return ({ msg: lang.flash_vat_unvalid, state: "warning" });
                        };

                        return userTokenRequestPost("updateBillingInfo", localBillingInfo, "billingInfo");
                    };
                }, [userTokenRequestPost, userData.billingInfo]);



            //------------- Request for update anonymize -------------//

                const updateAnonymize = useMemo(() => {
                    return value => userTokenAndApiKeyRequestGet("anonymize", value);
                }, [userTokenAndApiKeyRequestGet]);



            //------------- Request for update learnable -------------//

                const updateLearnable = useMemo(() => {
                    return value => userTokenAndApiKeyRequestGet("learnable", value);
                }, [userTokenAndApiKeyRequestGet]);


            
            //------------- Request for update anonymize -------------//

                const updateLimits = useMemo(() => {
                    return async (softLimit, hardLimit) => {
                        const validLimits = {
                            softLimit: true,
                            hardLimit: true
                        };

                        if(!userData.paymentInfo.stripedCards?.length){
                            Flash({ msg: lang.flash_limit_payment, state: "warning" });
                            return;
                        };

                        if(softLimit === userData.apiUsage.billingPeriod.softLimit){
                            validLimits.softLimit = false;
                        }; 
                        if(hardLimit === userData.apiUsage.billingPeriod.hardLimit){
                            validLimits.hardLimit = false;
                        };
                        if(!validLimits.softLimit && !validLimits.hardLimit){
                            Flash({ msg: lang.flash_no_value_limit, state: "classic" });
                            return;
                        };
                    
                        if (softLimit < userData.apiUsage.subscription.planQuota && softLimit !== -1) {
                            validLimits.softLimit = false;
                            Flash({ msg: lang.return_flash_soft_limit(userData.apiUsage.subscription.planQuota), state: "warning" });
                        };
                        if (hardLimit < userData.apiUsage.subscription.planQuota && hardLimit !== -1) {
                            validLimits.hardLimit = false;
                            Flash({ msg: lang.return_flash_hard_limit(userData.apiUsage.subscription.planQuota), state: "warning" });
                        };
                    
                        if (softLimit > hardLimit && hardLimit !== -1) {
                            Flash({ msg: lang.flash_higher_limit, state: "warning" });
                            return;
                        };
                    
                    
                        if(validLimits.softLimit) {
                            const options = {
                                action: "update",
                                category: "Update soft limit",
                                dataName: "apiUsage",
                                flashErrMsg: lang.flash_update_soft_limit_error
                            };
                            const isSuccess = await userTokenRequestGet(`updateLimit/${softLimit}/false`, options);
                            if(isSuccess){
                                Flash({ msg: lang.flash_update_soft_limit_success, state: "success" });
                            };
                        };

                        if(validLimits.hardLimit) {
                            const options = {
                                action: "update",
                                category: "Update hard limit",
                                dataName: "apiUsage",
                                flashErrMsg: lang.flash_update_hard_limit_error
                            };
                            const isSuccess = await userTokenRequestGet(`updateLimit/${hardLimit}/true`, options);
                            if(isSuccess){
                                Flash({ msg: lang.flash_update_hard_limit_success, state: "success" });
                            };
                        };
                    };

                }, [userTokenRequestGet, userData.apiUsage, userData.paymentInfo?.stripedCards]);



            //------------------------- Update default card ------------------//

                const udpateDefaultCard = useMemo(() => {
                    return async card => {
                        const options = {
                            action: "update",
                            category: "Update default card",
                            dataName: "paymentInfo",
                            flashErrMsg: lang.flash_update_default_card_error
                        };
                        const isSuccess = userTokenRequestGet(`updatePaymentDefault/${card.sourceId}`, options);
                        if(isSuccess){
                            Flash({ msg: lang.flash_update_default_card_success, state: "success" });
                        };
                    };
                }, [userTokenRequestGet]);



        // ================================= DELETE ================================= //


            //------------- Request for delete account -------------//

                const deleteAccount = useMemo(() => {
                    return async () => {
                        const options = {
                            action: "delete",
                            category: "Delete account"
                        };
                        const isSuccess = await userTokenRequestGet('removeUserAccount', options);

                        if(isSuccess){
                            const flash = {
                                msg: lang.flash_delete_account,
                                state: "classic"
                            };
                            signOut(JSON.stringify(flash));
                        };
                    }
                 
                }, [userTokenRequestGet]);



            //------------- Request for delete account -------------//

                const deleteCard = useMemo(() => {
                    return async card => {
                        const userPlan = userData.apiUsage?.subscription.planName;
                        const userCardsNumber = userData.paymentInfo?.stripedCards?.length || 0;
                        const planQuota = userData.apiUsage?.subscription.planQuota;

                        if (userCardsNumber === 1 && userPlan !== "BASIC") {
                            Flash({ msg: lang.return_flash_delete_card_subscription_error(userPlan), state: "error" })
                        
                        } else {
                            const options = {
                                action: "delete",
                                category: "Delete card",
                                dataName: "paymentInfo",
                                flashErrMsg: lang.return_flash_delete_card_error(card.last4)
                            };
                            const isSuccess = await userTokenRequestGet(`removePayment/${card.sourceId}`, options);

                            if(isSuccess){
                                Flash({ msg: lang.return_flash_delete_card_success(card.last4), state: "success" });

                                if ((userCardsNumber - 1) === 0 && (userData.apiUsage.billingPeriod.softLimit !== planQuota ||  userData.apiUsage.billingPeriod.hardLimit !== planQuota)) {
                                    await updateLimits(parseInt(planQuota), parseInt(planQuota));
                                };
                            };
                        };
                    };
                 
                }, [userTokenRequestGet, updateLimits, userData.apiUsage, userData.paymentInfo]);



            


            // ================================= SIGNOUT ================================= //

                const signOut = useMemo(() => {
                    return flash => {
                        firebase.logout().then(() => {
                            dispatchUserData({type: "reset"});
                            window.localStorage.clear();
                            if(typeof window.gtag === "function"){
                                window.gtag('event', 'sign_out', {
                                  'event_category' : 'SignOut',
                                });
                            };
                        });
                    };
                }, [firebase]);




            // ================================= CHARGE ================================= //


                //------------- Request for charge customer -------------//

                    const chargeCustomer = useMemo(() => {
                        return async choosedPlanInfo => {
                            await axios.get(`${requestAPIPath}subscribePlan/${choosedPlanInfo.planName}/${userData.userToken}`)
                            .then(() => {
                                if(typeof window.gtag === "function"){
                                    const isDowngrade = choosedPlanInfo.planQuota < userData.apiUsage?.subscription?.planQuota;
                                    
                                    window.gtag('event', 'upgrade', {
                                        'event_category' : 'Submited plan',
                                        'event_label' : 'Payment section',
                                        'event_result': 'Success',
                                        'selected_plan': choosedPlanInfo.planName,
                                        'downgrade_plan': isDowngrade ? "true" : "false",
                                        'downgrade_value': isDowngrade ? userData.apiUsage?.subscription?.price - choosedPlanInfo.price : 0,
                                        'upgrade_value': !isDowngrade ? choosedPlanInfo.price - userData.apiUsage?.subscription?.price : 0,
                                        'old_plan': userData.apiUsage.subscription?.planName,
                                        'subscribed_plan_value': choosedPlanInfo.price,
                                        'value': choosedPlanInfo.price
                                    });
                                };

                                userTokenRequestGet("billingInfo");
                                userTokenRequestGet("billingHistory");
                                userTokenRequestGet("userInfo");
                                userTokenRequestGet("paymentInfo");
                                apiKeyRequestGet("apiUsage");
                                apiKeyRequestGet("apiUsageHistory");

                                const getClientReferenceId = () => {
                                    return window.Rewardful && window.Rewardful.referral || false;
                                };

                                if(getClientReferenceId()){
                                    window.rewardful('convert', { email: userData.user?.email })
                                };

                                window.localStorage.setItem('flash', JSON.stringify({ msg: lang.return_flash_register_plan_success(choosedPlanInfo.planName.charAt(0) + choosedPlanInfo.planName.slice(1).toLowerCase()), state: "success" }));
                                window.localStorage.removeItem("choosedPlan");
                                navigate(lang.my_account_link);
                            })
                            .catch(err => {
                                Flash({ msg: lang.return_flash_register_plan_error(choosedPlanInfo.planName), state: "error" });
                                if(typeof window.gtag === "function"){
                                    window.gtag('event', 'upgrade', {
                                      'event_category' : 'Submited plan',
                                      'event_label' : 'Payment section',
                                      'event_result': 'Error',
                                      'selected_plan': choosedPlanInfo.planName,
                                      'value': choosedPlanInfo.price,
                                      'error_msg': err
                                    });
                                };
                            })
                        };
                    }, [userData.user, userTokenRequestGet, apiKeyRequestGet, userData.apiUsage?.subscription]); 




            //------------- Update payment info -------------//
                
                const updatePaymentInfo = useMemo(() => {
                    return () => userTokenRequestGet("paymentInfo");
                }, [userTokenRequestGet]);


            //------------- Function to update api usage -------------//

                const updateApiUsage = useMemo(()=> {
                    return () => Promise.all([
                        apiKeyRequestGet("apiUsage", true),
                        apiKeyRequestGet("apiUsageHistory", true),
                    ]);
                }, [apiKeyRequestGet]);



    return({
        updateName,
        updateEmail,
        updateBillingInfo,
        updateAnonymize,
        updateLearnable,
        updateLimits,
        udpateDefaultCard,
        deleteAccount,
        deleteCard,
        signOut,
        chargeCustomer,
        updatePaymentInfo,
        updateApiUsage
    })
};
import {useMemo} from "react";
import axios from "axios";
import { Flash } from "../functions/Flash";

import { useInitUser as lang } from "../../lang/utils/user-hooks-and-provider/lang"

const requestAPIPath = "https://v2.namsor.com/NamSorAPIv2/api2/json/";
// const requestAPIPath = "https://v2uk.namsor.com/NamSorAPIv2/api2/json/";

const errorCounter = {
    userToken: 0,
    apiKey: 0,
    billingInfo: 0,
    billingHistory: 0,
    userInfo: 0,
    paymentInfo: 0,
    apiUsage: 0,
    apiUsageHistory: 0
};

const errorMessage = lang.errorMessage;

const successMessage = {
    learnabletrue: "API key set as 'learnable'",
    learnablefalse: "API key set as 'non learnable'",
    anonymizetrue: "API key set as 'anonymized'",
    anonymizefalse: "API key set as 'non anonymized'"
};

const updatedInformation = {
    updateBillingInfo: "billing informations",
};

const valuesToRename = {
    anonymize: "apiKeyInfo",
    learnable: "apiKeyInfo",
};



export const useInitUser = ({userData, dispatchUserData}) => {


    // ================================= GLOBAL REQUEST ================================= // 

        //------------- Get API version -------------//

            useMemo(() => {
                axios.get('https://v2.namsor.com/NamSorAPIv2/api2/json/softwareVersion')
                .then(res => {
                    dispatchUserData({
                        type: "update",
                        values: {
                            apiVersion: res.data?.softwareVersion?.join(".")
                        },
                        isInit: true
                    });
                })
                .catch(err => {
                    
                })
            }, []);
   


        //------------- Get user token -------------//

            const getUserToken = useMemo(() => {
                return async () => {
                    if(userData.user){
                        let error;
                        const userToken = userData.user.accessToken;

                        if(userToken){
                            dispatchUserData({
                                type: "update",
                                values: {
                                    userToken
                                },
                                isInit: true
                            });

                        }else if(errorCounter.userToken < 6){
                            errorCounter.userToken++;
                            setTimeout(getUserToken, 1000);

                        }else{
                            Flash({msg: errorMessage.userToken, state: "error"});
                            if(typeof window.gtag === "function"){
                                window.gtag('event', 'get', {
                                    'event_category' : 'userToken',
                                    'event_result': "Error",
                                    'error_msg': error
                                });
                            };
                        };
                    };
                };
            }, [userData.user]);



        //------------- Init user token -------------//
    
            useMemo(getUserToken, [userData.user]);



        //------------- Get api key-------------//

            const getApiKey = useMemo(() => {
                return async () => {
                    if(userData.userToken){
                        let error;

                        const apiKey = await axios(`${requestAPIPath}retrieveKey/${userData.userToken}`)
                        .then(res => res.data.api_key)
                        .catch(err => {
                            error = err
                            return undefined
                        });
    
                        if(apiKey){
                            dispatchUserData({
                                type: "update",
                                values: {
                                    apiKey
                                },
                                isInit: true
                            });

                        }else if(errorCounter.apiKey < 6){
                            errorCounter.apiKey++;
                            setTimeout(getApiKey, 1000);

                        }else if(errorCounter.apiKey === 6){
                            Flash({msg: errorMessage.apiKey, state: "error"});
                            if(typeof window.gtag === "function"){
                                window.gtag('event', 'get', {
                                    'event_category' : 'apiKey',
                                    'event_result': "Error",
                                    'error_msg': error
                                });
                            };
                        };
                    }
                };
            }, [userData.userToken]);



        //------------- Init Api key -------------//
    
            useMemo(getApiKey, [userData.userToken, userData.updateKey]);





    // ================================= REQUEST WITH USER TOKEN ================================= //


        //------------- Get -------------//

            const userTokenRequestGet = useMemo(() => {
                return (path, options, isInit) => {
                    if(userData.userToken){
                        return axios.get(`${requestAPIPath}${path}/${userData.userToken}`)
                        .then(res => {
                            dispatchUserData({
                                type: "update",
                                values: {
                                    [options?.dataName || path]: res.data || {}
                                },
                                isInit
                            });

                            if(options?.action){
                                if(typeof window.gtag === "function"){
                                    window.gtag('event', options.action, {
                                        'event_category' : (options.category || path),
                                        'event_result' : 'Success',
                                    });
                                };
                            };

                            return true;
                        })
                        .catch(err => {
                            if(errorCounter[path] < 6 && !options?.action){
                                errorCounter[path]++;
                                setTimeout(() => userTokenRequestGet(path, options, isInit), 1000);
                            }else{
                                Flash({msg: (options?.flashErrMsg || errorMessage[path]), state: "error"});
                                if(typeof window.gtag === "function"){
                                    window.gtag('event', (options?.action || 'get'), {
                                        'event_category' : (options?.category || path),
                                        'event_result': "Error",
                                        'error_msg': err
                                    });
                                };
                            };

                            return false;
                        })
                    };
                };
            }, [userData.userToken]);



        //------------- Init data with user token -------------//

            useMemo(()=> {
                if(userData.userToken){
                    Promise.all([
                        userTokenRequestGet("userInfo", undefined, true),
                        userTokenRequestGet('availablePlans', undefined, true)
                    ])
                };
                if(userData.userToken && userData.apiKey){
                    Promise.all([
                        userTokenRequestGet("billingInfo", undefined, true),
                        userTokenRequestGet("billingHistory", undefined, true),
                        userTokenRequestGet("paymentInfo", undefined, true),
                    ])
                };
            }, [userData.userToken, userData.apiKey, userData.updateKey]);

           



        //------------- Post -------------//

            const userTokenRequestPost = useMemo(() => {
                return (path, data, name) => axios({
                    method: "POST",
                    url: `${requestAPIPath}${path}/${userData.userToken}`,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: JSON.stringify(data)
                })
                .then(res => {
                    if(typeof window.gtag === "function"){
                        window.gtag('event', 'update', {
                            'event_category' : `Update ${updatedInformation[path]}`,
                            'event_result' : 'Success',
                        });
                    };

                    dispatchUserData({
                        type: "update",
                        values: {
                            [name]: res.data
                        }
                    });

                    return ({ msg: lang.return_flash_updated_success(lang.updatedInformation[path]), state: "success" });

                })
                .catch(err => {
                    if(typeof window.gtag === "function"){
                        window.gtag('event', 'update', {
                            'event_category' : `Update ${updatedInformation[path]}`,
                            'event_result' : 'Error',
                            'error_msg': err
                        });
                    };

                    return ({msg: lang.return_flash_updated_error(lang.updatedInformation[path]), state: "error" });
                });
            }, [userData.userToken]);






    // ================================= REQUEST WITH API KEY ================================= //


        //------------- Get -------------//

            const apiKeyRequestGet = useMemo(() => {
                return (path, isInit) => {
                    if(userData.apiKey){
                        return axios.get(
                            `${requestAPIPath}${path}`,
                            { headers: { 'X-API-KEY': userData.apiKey } }
                        )
                        .then(res => {
                            dispatchUserData({
                                type: "update",
                                values: {
                                    [path]: res.data || {}
                                },
                                isInit
                            });
                        })
                        .catch(err => {
                            if(errorCounter[path] < 6){
                                errorCounter[path]++;
                                setTimeout(() => apiKeyRequestGet(path, isInit), 1000);
                            }else{
                                Flash({msg: errorMessage[path], state: "error"});
                                if(typeof window.gtag === "function"){
                                    window.gtag('event', 'get', {
                                        'event_category' : path,
                                        'event_result': "Error",
                                        'error_msg': err
                                    });
                                };
                            };
                        });
                    };
                };
            }, [userData.apiKey]);



        //------------- Init data with API key -------------//

            useMemo(()=> {
                if(userData.apiKey){
                    Promise.all([
                        apiKeyRequestGet("apiUsage", true),
                        apiKeyRequestGet("apiUsageHistory", true),
                        apiKeyRequestGet("apiKeyInfo", true)
                    ]);
                };
            }, [userData.apiKey, userData.updateKey]);


    


    // ================================= REQUEST WITH API KEY AND USER TOKEN ================================= //


        //------------- Get -------------//

            const userTokenAndApiKeyRequestGet = useMemo(() => {
                return (path, value) => {
                    if(userData.apiKey && userData.userToken){
                        return axios.get(
                            `${requestAPIPath}${path}/${userData.apiKey}/${value}/${userData.userToken}`,
                            { headers: { 'X-API-KEY': userData.apiKey } }
                        )
                        .then(res => {
                            if(typeof window.gtag === "function"){
                                window.gtag('event', 'update', {
                                    'event_category' : `Update ${path}`,
                                    'event_result' : 'Success',
                                });
                            };
        
                            dispatchUserData({
                                type: "update",
                                values: {
                                    [valuesToRename[path] || path]: res.data
                                }
                            });
        
                            Flash({ msg: successMessage[`${path}${value}`], state: "success" });
                        })
                        .catch(err => {
                            if(typeof window.gtag === "function"){
                                window.gtag('event', 'update', {
                                    'event_category' : `Update ${path}`,
                                    'event_result' : 'Error',
                                    'error_msg': err
                                });
                            };

                            Flash({ msg: errorMessage[path], state: "error" });
                        })
                    };
                };
            }, [userData.apiKey, userData.userToken]);


    // ================================= PUBLIC FUNCTIONS ================================= //

        return ({
            userTokenRequestGet,
            apiKeyRequestGet,
            userTokenRequestPost,
            userTokenAndApiKeyRequestGet
        });
};
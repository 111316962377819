import firebaseConfig from "./config"
import {initializeApp} from "firebase/app"
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  updateProfile,
  updateEmail 
} from "firebase/auth"


const app = initializeApp(firebaseConfig);

class Firebase {
  constructor() {
    if (!firebaseInstance) {
      this.auth = getAuth(app)
    }
  }

  async register({ email, password, displayName, captchaValue }) {
    return createUserWithEmailAndPassword(this.auth, email, password)
    .then((userCredential) => {
      updateProfile(this.auth.currentUser, {displayName});
      userCredential.operationType = "signUp";
      return userCredential;
    })
  }

  async login({ email, password }) {
    return signInWithEmailAndPassword(this.auth, email, password)
  }

  async logout() {
    await signOut(this.auth)
  }

  async resetPassword(email){
    return sendPasswordResetEmail(this.auth, email)
  }

  async updateDisplayName(displayName) {
    return updateProfile(this.auth.currentUser, {displayName})
  }

  async updateEmail(email) {
    return updateEmail(this.auth.currentUser, email)
  }
}

let firebaseInstance

function getFirebaseInstance() {
  if (!firebaseInstance) {
    firebaseInstance = new Firebase()
    return firebaseInstance
  } else if (firebaseInstance) {
    return firebaseInstance
  } else {
    return null
  }
}

export default getFirebaseInstance
/* ================================================================================================
                                             FLASH
==================================================================================================*/

/* ========= Liste des instances de flashs ===========*/
    const flashs = {};


    const success = `<div class="c-flash_icon c-flash_icon--success animate font-normal"><span class="line tip"></span><span class="line long"></span><div class="placeholder"></div><div class="fix"></div></div>`;
    const error = `<div class="c-flash_icon c-flash_icon--error animate font-normal"><span class="x-mark"><span class="line left"></span><span class="line right"></span></span></div>`;
    const warning = `<div class="c-flash_icon c-flash_icon--warning font-normal"><div class="letter">!</div></div>`;
    const info = `<div class="c-flash_icon c-flash_icon--info font-normal"><div class="letter">i</div></div>`;
    

// ================= Valeurs communes à tous les Flashs (instances de Flash) ================//
    const indicator = {
        icons: {error, success, warning, classic: info},
        flashWidth: 290,
        flashId: 0,
        flashPosition : 0,
        flashActive : 0,

        get returnFlashId(){
            this.flashId ++;
            const maxContent = Math.floor(window.innerWidth / 288);
            this.flashPosition = this.flashActive ? this.flashPosition + 1 > maxContent ? 1 : this.flashPosition + 1 : 1;
            this.flashActive ++;
            return {id: this.flashId, position: this.flashPosition - 1, width: this.flashWidth}
        }
    };


// ============================= Flash => CONSTRUCTEUR DES FLASHS ========================//
    function FlashConstructor(message, type){
        this.type = type;
        this.icon = indicator.icons[type];
        this.message = message;
        this.value = indicator.returnFlashId;
    };

    FlashConstructor.prototype.infoMessage = function(){
        return(
            `<div id="info-${this.value.id}" class="flash ${this.type}" role="alert">
                <div>
                    ${this.icon}
                </div>
                <p>${this.message}</p>
            </div>`
        );
    };

    FlashConstructor.prototype.insert = function(id){
        const body = document.querySelector("body");
        body.insertAdjacentHTML("beforeend", this.infoMessage());
        const target = document.getElementById(`info-${this.value.id}`);
        target.style.right= `${this.value.position * this.value.width}px`;
        setTimeout(() => target.classList.add('visible'), 50);
        setTimeout(() => target.classList.remove('visible'), 6500);
        setTimeout(() => {
            target.remove();
            indicator.flashActive--;
            delete flashs[id];
        }, 7000);
    };

    

// ============================= FONCTION PUBLIQUE ========================//

    //Création des instances de Flash
    export const Flash = ({msg, state}) => {
        const id = indicator.flashId;
        flashs[id] = new FlashConstructor(msg, state);
        flashs[id].insert(id);
    };
import { checkVAT, countries } from 'jsvat'
import { isObjectsAreDifferent } from "./object"
import { Flash } from "./Flash"
import { changeDate } from "./date"
import { addEvent, removeEvent, bySelector, hideModal, showModal } from "./dom"
import { europeanCountriesList } from "../list/countriesList"

import { utils as lang } from "../../lang/utils/functions/lang"



//------------- Analyse biling information -------------//

  export const analyzeBillingInfo = (localBillingInfo, billingInfo) => {       
        const billingIsEmpty = Object.values(localBillingInfo).every(x => x === '');
        
        let objectsAreDifferent = isObjectsAreDifferent(localBillingInfo, billingInfo);
        return {billingIsEmpty, objectsAreDifferent}
  }




//------------- Validate VAT ID function -------------//

  export const validateVatId = billingValues => {
    if (billingValues?.vatID && europeanCountriesList[billingValues?.addressCountry]) {
      const vatVerif = checkVAT(billingValues?.vatID, countries);

      if (!vatVerif.isValid) {
        if(typeof window.gtag === "function"){
          window.gtag('event', 'validate', {
              'event_category' : 'VAT is not valid',
              'validate_result' : 'Reject: not a valid VAT ID',
              'event_data' : billingValues?.addressCountry,
          });
        };
          return false
      }else{
        return true;
      }
    }else{
      return true;
    }
  };




  //------------------------- Display quota function ------------------//

    export const displayQuota = (plan) => {
      if (plan === "BASIC") return "5000";
      else if (plan === "PRO") return "10 000";
      else if (plan === "ULTRA") return "100 000";
      else if (plan === "MEGA") return "1 000 000";
    };




//------------------------- Copy api key function ------------------//

  export const copyApiKey = (id, label) => {
    const copyText = document.getElementById(id);
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
    Flash({ msg: lang.flash_api_key_copied, state: "success" });
    if(typeof window.gtag === "function"){
      window.gtag('event', 'copy', {
        'event_category' : 'Copy API key',
        'event_label' : label,
      });
    };
  };




//------------------------- Format billing date function ------------------//

  export const formatBillingDate = date => {
    const bilingDay = new Date(date).getDate();
    const currentDate = new Date(new Date());
    const currentDay = currentDate.getDate();
    const nextBilingDate = new Date(currentDate.setDate(bilingDay));

    if (bilingDay > currentDay) return changeDate(nextBilingDate);
    else return changeDate(new Date(nextBilingDate.setMonth(currentDate.getMonth() + 1)));
  };




//------------------------- Change plan tracker ------------------//

  export const changePlanTracker = label => {
    let called = false;

    if(typeof window.gtag === "function"){
      window.gtag('event', 'upgrade', {
        'event_category' : 'Change plan',
        'event_label' : label,
        'event_callback': () => {
          called = true;
          window.open(lang.prices_link, '_blank')
        },
      });
    };
    
    setTimeout(() => {
      if(!called){
        window.open(lang.prices_link, '_blank');
      }
    }, 1000);
  };


//------------------------- Show invoice modal ------------------//

  export const showInvoiceDialog = invoiceId => {
    const dialog = bySelector(`#${invoiceId} .dialog-invoice`);
    const closeButton = bySelector(`#${invoiceId} .close-button`);
    const modalBackground = bySelector(`#${invoiceId} .modal-background`);

    const closeThisDialog = () => closeDialog(invoiceId);
    const closeThisDialog2 = event => {
        if(event.target.classList.value.includes('modal-background')){
            hideModal(dialog);
        };
    };

    const closeDialog = () => {
        hideModal(dialog);
        removeEvent(closeButton, 'click', closeDialog);
        removeEvent(modalBackground, 'click', closeThisDialog2);
    };

    showModal(dialog);
    addEvent(closeButton, 'click', closeThisDialog);
    addEvent(modalBackground, 'click', closeThisDialog2);
};